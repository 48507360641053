import router from './router'
import store from './store'
import helper from './helper'

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '启迪城服'
  const token = store.state.token
  if (process.env.NODE_ENV === 'production' && !token) { // 如果微信内打开，且没有token，继续微信登录判定。
    const code = helper.queryUrl('code')
    const state = helper.queryUrl('state')
    if (code && state) { // 如果没有token，但有code，代表刚从微信登录跳回的，取code接口换token
      store.dispatch('updateToken', { code, state }).then(() => {
        next({
          path: to.path,
          params: to.params,
          query: { appid: state },
          replace: true
        })
      })
    } else {
      const url = encodeURI(window.location.href)
      const appid = helper.queryUrl('appid') || 'wxd74edb5408c7d423'
      window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=${appid}#wechat_redirect`)
    }
  } else { // 如果不是微信内打开，通过app.vue创建覆层提示用户复制链接到微信打开，避免跳转丢失url。
    if (!to.query.appid && from.query.appid) {
      to.query.appid = from.query.appid
      next(to)
    } else {
      next()
    }
    
  }
  window.scrollTo(0, 0)
})
